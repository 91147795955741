import HNText from '@/components/HNText';
import { styled } from 'styled-components';
import { Image } from '@nextui-org/react';
import HNButton from '@/components/HNButton';
import { useRouter } from 'next/router';

const LandingPage = () => {
    const router = useRouter();

    const startClicked = () => {
        router.push('/catalog');
    };

    const contactClidked = () => {
        router.push('/contactUs');
    };

    return (
        <Container>
            <InnerContainer>
                <RightContainer>
                    <TextContainer>
                        <Title>הזמנינא</Title>
                        <Subtitle>עורך הזמנות לחתונה, באיכות של בית דפוס.</Subtitle>
                    </TextContainer>
                    <ButtonsContainer>
                        <StyledButton style="lg" onClick={startClicked}>
                            צור הזמנה
                        </StyledButton>
                        <SecondaryStyledButton style="lg-secondary" onClick={contactClidked}>
                            צור קשר
                        </SecondaryStyledButton>
                    </ButtonsContainer>
                </RightContainer>
                <LeftContainer>
                    <Image src="/sample_invite.jpeg" alt="invitiation" width={500} height={700} />
                </LeftContainer>
            </InnerContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-block-start: 100px;
    width: 90%;
    max-width: 1000px;
    direction: rtl;

    @media screen and (max-width: 660px) {
        flex-direction: column;
        justify-content: flex-start;
        margin-block-end: 50px;
        margin-block-start: 50px;
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 60%;
    height: 80%;

    @media screen and (max-width: 660px) {
        width: 100%;
        align-items: center;
        margin-block-end: 100px;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 35%;

    @media screen and (max-width: 660px) {
        width: 100%;
        align-items: center;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 660px) {
        align-items: center;
    }
`;

const Title = styled(HNText)`
    font-size: 120px;
    line-height: 1;
    margin-block-end: 10px;

    @media screen and (max-width: 660px) {
        margin-block-end: 30px;
    }
`;

const Subtitle = styled(HNText)`
    font-size: 30px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width: 660px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-block-start: 50px;
    }
`;

const StyledButton = styled(HNButton)`
    width: 180px;

    @media screen and (max-width: 660px) {
        width: 80%;
        align-self: center;
    }
`;

const SecondaryStyledButton = styled(HNButton)`
    width: 180px;

    @media screen and (max-width: 660px) {
        width: 80%;
        align-self: center;
    }
`;

export default LandingPage;
